<script setup lang="ts">
const { t } = useT();
const { open } = useTaoModals();
const { state, resetState } = usePiggy({ open });

const handleClick = () => {
	navigateTo("/piggy-bank/");
};

onUnmounted(() => {
	resetState();
});
</script>

<template>
	<div :class="['gift-piggy', { opened: state?.betAnimation }]" @click="handleClick">
		<NuxtImg
			src="/nuxt-img/piggy-bank/label-piggy-gift.png"
			class="gift-img"
			height="96"
			width="96"
			loading="lazy"
			format="avif"
			alt="gift-img"
		/>
		<AAnimationJittery class="gift-animation">
			<ABadge
				v-if="state?.betAnimation && state?.prizeAmount"
				variant="info"
				autosize
				class="gift-label"
				background="var(--success-500)"
			>
				<AText type="xx-small" :modifiers="['uppercase', 'semibold', 'nowrap', 'condensed']">
					{{ t("+ SC", { winCoins: state.prizeAmount }) }}
				</AText>
			</ABadge>
		</AAnimationJittery>
		<AText type="small" :modifiers="['center', 'uppercase', 'bold']" as="div">
			{{ t("Piggy") }}
			<br />
			<AText type="x-small" :modifiers="['capitalize', 'normal']">{{ t("Bank") }}</AText>
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.gift-piggy {
	position: fixed;
	cursor: pointer;
	bottom: 400px;
	right: -120px;
	width: 90px;
	height: 60px;
	padding: 5px;
	background: var(--gradient-7);
	border-radius: 12px 0 0 12px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
	transition: right 0.6s ease-in-out;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
	color: var(--gray-50);
	z-index: 10003;

	.gift-animation {
		position: absolute;
		left: -10px;
		bottom: 6px;
	}

	.gift-label {
		display: inline-flex;
		border-radius: 30px;
		color: var(--gray-50);
		min-height: 20px;
	}

	.gift-img {
		width: 48px;
		position: absolute;
		left: -10px;
		top: calc(50% - 24px);
		height: 48px;
		object-fit: cover;
	}

	&.opened {
		right: 0;
	}
}
</style>
